<!--
--------------------------------------------------------------------------
   Sitemap.vue
--------------------------------------------------------------------------

    Component to build sitemap screen

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div id="sitemap" class="view">
        <h1 v-translate>Sitemap</h1>
        <ul class="triangle-list">
            <li v-translate>Home</li>
            <li v-for="section in $store.state.sections.filter(s => s.menu === 'main')" :key="section.id">
                <a :href="section.path[$language.current]" :title="section.menuName[$language.current]" :target="section.newWindow ? '_blank' : '_self'" v-if="section.type === 'link'">
                    {{ section.menuName[$language.current] }}
                </a>
                <router-link :to="section.path[$language.current]" :title="section.menuName[$language.current]" v-else>
                    {{ section.menuName[$language.current] }}
                </router-link>
            </li>
            <li v-for="section in $store.state.sections.filter(s => s.menu !== 'top' && s.menu !== 'main' && s.menu !== 'bottom')" :key="section.id">
                <a :href="section.path[$language.current]" :title="section.fullName[$language.current]" :target="section.newWindow ? '_blank' : '_self'" v-if="section.type === 'link'">
                    {{ section.fullName[$language.current] }}
                </a>
                <router-link :to="section.path[$language.current]" :title="section.fullName[$language.current]" v-else>
                    {{ section.fullName[$language.current] }}
                </router-link>
            </li>
            <li>
                <router-link :to="'/news'|translate" :title="'News'|translate">
                    {{ $gettext('News') }}
                </router-link>
            </li>
            <li v-for="section in $store.state.sections.filter(s => s.menu === 'top')" :key="section.id">
                <a :href="section.path[$language.current]" :title="section.menuName[$language.current]" :target="section.newWindow ? '_blank' : '_self'" v-if="section.type === 'link'">
                    {{ section.menuName[$language.current] }}
                </a>
                <router-link :to="section.path[$language.current]" :title="section.menuName[$language.current]" v-else>
                    {{ section.menuName[$language.current] }}
                </router-link>
            </li>
            <li v-for="section in $store.state.sections.filter(s => s.menu === 'bottom')" :key="section.id">
                <a :href="section.path[$language.current]" :title="section.menuName[$language.current]" :target="section.newWindow ? '_blank' : '_self'" v-if="section.type === 'link'">
                    {{ section.menuName[$language.current] }}
                </a>
                <router-link :to="section.path[$language.current]" :title="section.menuName[$language.current]" v-else>
                    {{ section.menuName[$language.current] }}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'sitemap',
        mounted() {
            // Set base path
            this.$store.commit('setCurrentPath', '/sitemap');

            // Set breadcrumbs
            this.$store.commit('setBreadcrumbs', [{ text: this.$gettext('Home'), to: '/' }, { text: this.$gettext('Sitemap'), active: true }]);

            // Set metadata
            this.setMetadata(this.$gettext('Sitemap') + ' | ' + this.$gettext(this.$store.state.metadata.title), 'Sitemap of the portal', this.$gettext('sitemap') + ',' + this.$gettext(this.$store.state.metadata.keywords));
        }
    };
</script>